export const slider = (ms = 5000, delay = 0) => ({
  current: 0,
  timer: null,
  timerInterval: null,

  init() {
    this.count = this.$el.querySelectorAll('.slider__item').length;
    this.run();
  },

  isCurrentSlide(index) {
    return this.current === index;
  },

  effect(visible) {
    if (visible) {
      this.run();
    } else {
      this.stop();
    }
  },

  run() {
    if (this.count > 1) {
      this.timer = setTimeout(() => {
        this.timerInterval = setInterval(() => {
          this.next();
        }, ms);
      }, delay);
    }
  },

  stop() {
    clearTimeout(this.timer);
    clearInterval(this.timerInterval);
  },

  next() {
    this.stop();
    this.current += 1;
    if (this.current === this.count) {
      this.current = 0;
    }
    this.run();
  },

  prev() {
    this.stop();
    this.current -= 1;
    if (this.current === -1) {
      this.current = this.count - 1;
    }
    this.run();
  },
});
