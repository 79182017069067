import { SubscribeMessage } from '../const';

export const subscribe = () => ({
  send(evt) {
    evt.preventDefault();

    const emailElement = document.getElementById('email');
    const nameElement = document.getElementById('name');
    const agreementElement = document.getElementById('agreement');
    const agreementLabelElement = document.querySelector('.subscribe__agreement');

    if (!agreementElement.checked) {
      agreementLabelElement.classList.add('subscribe__agreement--attention');
      setTimeout(() => {
        agreementLabelElement.classList.remove('subscribe__agreement--attention');
      }, 5000);
      return;
    }

    fetch('https://blv.ru/_php/subscribe.php', {
      method: 'POST',
      body: new FormData(evt.target),
    }).then((res) => {
      this.showMessage(res.status === 200 ? SubscribeMessage.SUCCESS : SubscribeMessage.ERROR, `main-message main-message--${res.status === 200 ? 'success' : 'error'}`);
    }).catch(() => {
      this.showMessage(SubscribeMessage.ERROR, 'main-message main-message--error');
    });

    emailElement.value = '';
    nameElement.value = '';
  },

  showMessage(message, className = '', timeout = 3000) {
    const wrapperElement = document.createElement('div');
    wrapperElement.innerHTML = `<div${className ? ` class="${className}"` : ''}>${message}</div>`;

    const messageElement = wrapperElement.children[0];
    this.$root.appendChild(messageElement);
    setTimeout(() => {
      messageElement.remove();
    }, timeout);
  },
});
