import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import { divisions } from './components/divisions';
import { slider } from './components/slider';
import { subscribe } from './components/subscribe';
import { fashionDivision } from './components/fashion-division';

window.Alpine = Alpine;
Alpine.plugin(collapse);

Alpine.data('divisions', divisions);
Alpine.data('slider', slider);
Alpine.data('subscribe', subscribe);
Alpine.data('fashionDivision', fashionDivision);

Alpine.start();
