export const fashionDivision = (base = 'Третьяковский%20проезд') => ({
  title: 'Одежда, обувь, аксессуары',
  brands: [],

  init() {
    fetch(`https://data.tretyakovsky.ru/api/bases?bases[]=${base}`)
      .then((res) => res.json())
      .then((bases) => {
        this.setBases()(bases[0].brands.filter(({ id }) => id !== 90 && id !== 147));
      });
  },

  setBases() {
    return (brands) => {
      this.brands = brands
        .filter(({ name }) => !/women|Campagna|Couture/.test(name))
        .map((it) => ({
          ...it,
          href: `+${it.phone.replace(/\D/g, '')}`,
        }));
    };
  },
});
